import { either, isEmpty, isNil, map, omit, pipe, reject } from 'ramda';
import { capitalize } from './capitalize';

export const transformCustomTags = pipe<
    string[],
    Record<string, unknown>,
    Record<string, unknown>,
    Record<string, unknown>,
    Record<string, string>
>(
    omit,
    reject(either(isNil, isEmpty)),
    map((value: boolean | number | string) => {
        if (typeof value === 'boolean') {
            return capitalize(value.toString());
        }

        if (typeof value === 'number') {
            return value.toString();
        }

        return value;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as (dict: unknown) => any
);
