import { ConvivaMetadata, VideoAnalytics } from '@convivainc/conviva-js-coresdk';
import { assoc, omit } from 'ramda';
import {
    CDN_NAMES,
    DRM_TAGS,
    EXCLUDE_FROM_CUSTOM_TAGS_KEYS,
} from './consts';
import {
    AnalyticsService,
    AnalyticsSettings,
    ConvivaCore,
    CustomMetadata,
    DeviceData,
    DeviceMetadata,
    PlaybackEvent,
    PlayerInfo
} from './types';
import { CustomDeviceInfo } from './types/customDeviceInfo';
import { transformCustomTags } from './utils/transformCustomTags';

export class ConvivaService implements AnalyticsService {
    private readonly core: ConvivaCore;
    private readonly deviceData: DeviceData;
    private readonly playerName: string;
    private convivaVideoAnalytics: VideoAnalytics | null;
    private playerInfo: PlayerInfo | Record<string, never>;
    private deviceMetadata: DeviceMetadata | Record<string, never>;
    private customMetadata: CustomMetadata | Record<string, never>;
    private convivaSettings: AnalyticsSettings | Record<string, never>;
    private isAnalyticsObjectCreated = false;

    constructor(core: ConvivaCore, deviceData: DeviceData, playerName: string) {
        this.core = core;
        this.deviceData = deviceData;
        this.playerName = playerName;
        this.convivaVideoAnalytics = null;
        this.playerInfo = {};
        this.deviceMetadata = {};
        this.customMetadata = {};
        this.convivaSettings = {};
    }

    private initialize(): void {
        const { Analytics, Constants } = this.core;
        const { convivaKey, convivaURL } = this.convivaSettings;
        const settings = {
            [Constants.GATEWAY_URL]: convivaURL,
        }

        if (!this.isAnalyticsObjectCreated) {
            Analytics.init(convivaKey, null, settings);

            this.isAnalyticsObjectCreated = true;
        }

        this.setDeviceMetadata();
    }

    private setDeviceMetadata(): void {
        const { Constants, Analytics } = this.core;

        let deviceMetadata = {
            [Constants.DeviceMetadata.CATEGORY]: this.deviceData.category,
        };

        if (this.deviceData.model) {
            deviceMetadata = assoc(
                Constants.DeviceMetadata.MODEL,
                this.deviceData.model,
                deviceMetadata
            );
        }

        if (this.deviceData.type) {
            deviceMetadata = assoc(
                Constants.DeviceMetadata.TYPE,
                this.deviceData.type,
                deviceMetadata
            );
        }

        if (this.deviceData.osName) {
            deviceMetadata = assoc(
                Constants.DeviceMetadata.OS_NAME,
                this.deviceData.osName,
                deviceMetadata
            );
        }

        if (this.deviceData.osVersion) {
            deviceMetadata = assoc(
                Constants.DeviceMetadata.OS_VERSION,
                this.deviceData.osVersion,
                deviceMetadata
            );
        }

        if (this.deviceData.deviceBrand) {
            deviceMetadata = assoc(
                Constants.DeviceMetadata.BRAND,
                this.deviceData.deviceBrand,
                deviceMetadata
            );
        }


        Analytics.setDeviceMetadata(deviceMetadata);
    }

    private getContentInfo(): ConvivaMetadata {
        const predefinedTags = this.getPredefinedTags();
        const customTags = this.getCustomTags();

        return ({
            ...predefinedTags,
            ...customTags,
        });
    }

    private getPredefinedTags(): ConvivaMetadata {
        const { Constants } = this.core;
        const { streamUrl = null, streamType, viewerId } = this.deviceMetadata;
        const { assetName, duration } = this.customMetadata;

        return {
            [Constants.STREAM_URL]: streamUrl,
            [Constants.ASSET_NAME]: assetName,
            [Constants.IS_LIVE]: streamType,
            [Constants.PLAYER_NAME]: this.playerName,
            [Constants.VIEWER_ID]: viewerId,
            [Constants.DURATION]: duration,
            [Constants.DEFAULT_RESOURCE]: CDN_NAMES.UNKNOWN,
        } as ConvivaMetadata;
    }

    // TODO: add 'recovery' custom tag when segment recovery is implemented
    private getCustomTags(): Record<string, string> {
        const {
            playerVersion,
            streamingProtocol,
            securityLevel,
        } = this.playerInfo;

        const customTags = transformCustomTags(
            EXCLUDE_FROM_CUSTOM_TAGS_KEYS, {
            ...this.customMetadata,
            ...this.deviceMetadata,
            [DRM_TAGS.DEVICE_DRM_SECURITY_LEVEL]: securityLevel,
            [DRM_TAGS.PLAYBACK_DRM_SECURITY_LEVEL]: securityLevel,
        });

        return {
            ...customTags,
            playerVersion,
            streamingProtocol,
        }
    }

    private setContentInfo(): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const contentInfo = this.getContentInfo();

        this.convivaVideoAnalytics?.setContentInfo(contentInfo);
    }

    private reportVideoStateChange(state: string): void {
        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.PLAYER_STATE,
            state
        );
    }

    public setPlayerInfo(info: PlayerInfo): void {
        this.playerInfo = info;

        const { Constants } = this.core;
        const { playerName, playerVersion } = info;

        const playerInfo = {
            [Constants.FRAMEWORK_NAME]: playerName,
            [Constants.FRAMEWORK_VERSION]: playerVersion,
        };

        this.convivaVideoAnalytics?.setPlayerInfo(playerInfo);

        this.setContentInfo();
    }

    public setCustomDeviceInfo(customDeviceInfo: CustomDeviceInfo): void {
        this.deviceData.model = customDeviceInfo.model;
        this.deviceData.osVersion = customDeviceInfo.osVersion;
        this.deviceData.osName = customDeviceInfo.osName;
        this.deviceData.deviceBrand = customDeviceInfo.deviceBrand;
        this.deviceData.deviceType = customDeviceInfo.deviceType;
    }

    public startSession(deviceMetadata: DeviceMetadata, analyticsSettings: AnalyticsSettings): void {
        this.deviceMetadata = deviceMetadata;
        this.convivaSettings = analyticsSettings;

        this.endSession();
        this.initialize();

        const { Analytics } = this.core;

        this.convivaVideoAnalytics = Analytics.buildVideoAnalytics();

        const contentInfo = this.getContentInfo();

        this.convivaVideoAnalytics.reportPlaybackRequested(contentInfo);
    }

    public setMetadata(customMetadata: CustomMetadata): void {
        this.customMetadata = customMetadata;

        this.setContentInfo();
    }

    public setStreamUrl(url: string): void {
        this.deviceMetadata = assoc('streamUrl', url, this.deviceMetadata);

        this.setContentInfo();
    }

    public trackUserWaitStarted(): void {
        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackEvent(
            Constants.Events.USER_WAIT_STARTED
        );
    }

    public trackUserWaitEnded(): void {
        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackEvent(
            Constants.Events.USER_WAIT_ENDED
        );
    }

    public trackPlayEvent(): void {
        const { Constants } = this.core;

        this.reportVideoStateChange(Constants.PlayerState.PLAYING);
    }

    public trackPauseEvent(): void {
        const { Constants } = this.core;

        this.reportVideoStateChange(Constants.PlayerState.PAUSED);
    }

    public trackStopEvent(): void {
        const { Constants } = this.core;

        this.reportVideoStateChange(Constants.PlayerState.STOPPED);
    }

    public trackStartSeekEvent(position?: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        if (!position) {
            return this.convivaVideoAnalytics?.reportPlaybackMetric(
                Constants.Playback.SEEK_STARTED
            );
        }

        const seekTimeInMilliseconds = Math.trunc(position * 1000);

        return this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.SEEK_STARTED,
            seekTimeInMilliseconds
        );
    }

    public trackEndSeekEvent(): void {
        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.SEEK_ENDED
        );
    }

    public trackBufferingEvent(): void {
        const { Constants } = this.core;

        this.reportVideoStateChange(Constants.PlayerState.BUFFERING);
    }

    public trackBitrateEvent(bitrate: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        const bitrateKbps = Math.round(bitrate / 1024);

        if (!Number.isInteger(bitrateKbps)) {
            return;
        }

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.BITRATE,
            bitrateKbps
        );
    }

    public trackBufferLength(bufferLength: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.BUFFER_LENGTH,
            bufferLength
        );
    }

    public trackRenderedFrameRate(fps: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.RENDERED_FRAMERATE,
            fps
        );
    }

    private getPlaybackEventDetails(event: PlaybackEvent): Record<string, unknown> {
        return {
            appName: `${this.playerName} ${event.appVersion}`,
            ...omit(['eventName', 'appVersion'], event),
        };
    }

    public trackPlaybackEvent(event: PlaybackEvent): void {
        const eventDetails = this.getPlaybackEventDetails(event);

        this.convivaVideoAnalytics?.reportPlaybackEvent(
            event.eventName,
            eventDetails
        );
    }

    public trackAppEvent(event: string): void {
        this.core.Analytics.reportAppEvent(event);
    }

    public trackAppBackgrounded(): void {
        const { Analytics } = this.core;

        Analytics.reportAppBackgrounded();
    }

    public trackAppForegrounded(): void {
        const { Analytics } = this.core;

        this.initialize();
        Analytics.reportAppForegrounded();
    }

    public trackFatalError(message: string): void {
        this.convivaVideoAnalytics?.reportPlaybackFailed(message);
    }

    public trackPlaybackEnded(): void {
        this.convivaVideoAnalytics?.reportPlaybackEnded();
        this.deviceMetadata = {};
        this.customMetadata = {};
    }

    public endSession(): void {
        this.convivaVideoAnalytics?.release();
        this.convivaVideoAnalytics = null;
    }

    public trackRecoveryAttempt(): void {
        this.customMetadata = assoc('recovery', true, this.customMetadata);

        this.setContentInfo();
    }

    public trackManifestType(manifestType: string): void {
        this.customMetadata = assoc('manifestType', manifestType, this.customMetadata);

        this.setContentInfo();
    }

    public trackBackofficeLocation(location: string): void {
        this.customMetadata = assoc('backofficeLocation', location, this.customMetadata);

        this.setContentInfo();
    }

    public trackDroppedFrames(droppedFrames: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.DROPPED_FRAMES_COUNT,
            droppedFrames
        );
    }

    public trackPlayHeadTime(position: number): void {
        if (!this.convivaVideoAnalytics) {
            return;
        }

        const { Constants } = this.core;

        const positionInMilliseconds = Math.trunc(position * 1000);

        this.convivaVideoAnalytics?.reportPlaybackMetric(
            Constants.Playback.PLAY_HEAD_TIME,
            positionInMilliseconds
        );
    }

    public get version(): string {
        return this.core.Constants.version;
    }
}
