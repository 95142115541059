
export const PLATFORM_NAME = {
    TIZEN: 'tizen',
    WEB: 'web',
    WEBOS: 'webos',
};

export const PLAYER_NAME = {
    WEB: 'Shaka Player Web (Perseus)',
    TIZEN: 'Shaka Player Tizen (Perseus)',
    WEBOS: 'Shaka Player WebOS (Perseus)',
};

export const EXCLUDE_FROM_CUSTOM_TAGS_KEYS = [
    'streamUrl',
    'viewerId',
    'assetName',
    'duration',
];

export const CDN_NAMES = {
    VELOCIX: 'VELOCIX',
    AKAMAI: 'AKAMAI',
    UNKNOWN: 'UNKNOWN',
};

export const DRM_TAGS = {
    DEVICE_DRM_SECURITY_LEVEL: 'deviceDRMSecurityLevel',
    PLAYBACK_DRM_SECURITY_LEVEL: 'playbackDRMSecurityLevel',
};
