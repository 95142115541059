import ConvivaSDK from '@convivainc/conviva-js-coresdk';
import { ConvivaService } from './conviva';
import { toDeviceCategory, toPlayerName } from './utils/platformSpecific';
class FlutterWebConvivaService extends ConvivaService {
    constructor(platform: string) {
        const deviceData = {
            category: toDeviceCategory(platform),
        };
        const playerName = toPlayerName(platform);

        super(ConvivaSDK, deviceData, playerName);

        window.__flutter_web_conviva_service = this;
    }
}

declare global {
    interface Window {
        __flutter_web_conviva_service: FlutterWebConvivaService,
        WebConvivaServiceNamespace: {
            FlutterWebConvivaService: { new(platform: string): FlutterWebConvivaService },
        };
        webAdobeAnalyticsNameSpace: {
            FlutterAdobeAnalytics: { new(): import('./adobe/index').default };
        }
    }
}

window.WebConvivaServiceNamespace = {
    FlutterWebConvivaService
};

if (shouldInitializeAdobeAnalytics()) {
    import('./adobe/index').then(module => {
        const FlutterAdobeAnalytics = module.default;
        window.webAdobeAnalyticsNameSpace = {
            FlutterAdobeAnalytics,
        };
    }).catch(e => {
        console.error('Error initializing Adobe Analytics:', e);
    });
}

function shouldInitializeAdobeAnalytics(): boolean {
    const userAgent = navigator.userAgent;
    const chromiumMatch = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    const tizenMatch = userAgent.match(/Tizen\s([0-9]+\.[0-9]+)/);
    const webOSMatch = userAgent.match(/Web0?S/i);
    if (tizenMatch && tizenMatch[1]) {
        const tizenVersionString = tizenMatch[1];
        const versionParts = tizenVersionString.split('.');
        if (versionParts.length >= 2) {
            const major = versionParts[0] ? parseInt(versionParts[0], 10) : null;
            const minor = versionParts[1] ? parseInt(versionParts[1], 10) : null;
            if (major !== null && minor !== null && !isNaN(major) && !isNaN(minor)) {
                const tizenVersion = parseFloat(`${major}.${minor}`);
                if (tizenVersion < 6) {
                    return false;
                }
            }
        }
    }
    else if (webOSMatch && chromiumMatch && chromiumMatch[2]) {
        const chromiumVersion = parseInt(chromiumMatch[2], 10);
        if (chromiumVersion < 89) {
            return false;
        }
    }
    return true;
}

