import { Constants, ConvivaConstants, valueof } from '@convivainc/conviva-js-coresdk';
import { PLATFORM_NAME, PLAYER_NAME } from './../consts';

export const toDeviceCategory = (platform: string): valueof<ConvivaConstants['DeviceCategory']> => {
    if (platform === PLATFORM_NAME.TIZEN) {
        return Constants.DeviceCategory.SAMSUNG_TV;
    }

    if (platform === PLATFORM_NAME.WEBOS) {
        return Constants.DeviceCategory.LG_TV;
    }

    return Constants.DeviceCategory.WEB;
}

export const toPlayerName = (platform: string): string => {
    if (platform === PLATFORM_NAME.TIZEN) {
        return PLAYER_NAME.TIZEN;
    }

    if (platform === PLATFORM_NAME.WEBOS) {
        return PLAYER_NAME.WEBOS;
    }

    return PLAYER_NAME.WEB;
}